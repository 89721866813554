<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    height?: string
    img: string
    source?: string
    width?: string
  }>(),
  {
    height: '',
    source: '',
    width: '',
  },
)
defineSlots<{ default(): any }>()

const hasSizes = computed(() => props.width && props.height)
</script>

<template>
  <section class="video-bg">
    <video
      v-if="source"
      ref="video"
      :poster="`https://cdn.lecollectionist.com/lc${img}`"
      class="video-bg__player"
      muted="true"
      autoplay
      playsinline
      loop
    >
      <source :src="source" type="video/mp4" />
    </video>

    <BaseNuxtImg
      v-else
      :aspect-ratio="hasSizes ? undefined : '16/9'"
      class="video-bg__player"
      :src="img"
      alt="Le Collectionist"
      :width="width"
      :height="height"
    />
    <slot />
  </section>
</template>

<style>
.video-bg {
  @apply w-full relative;
}

.video-bg__player {
  object-fit: cover;
  z-index: -1;
  @apply w-full h-full absolute;
}
</style>
